import React from 'react'
import { Helmet } from 'react-helmet'
import PageWrapper from '../components/PageWrapper'
import MetaData from '../components/MetaData'
import Hero from '../sections/common/Hero'
import FeeSection from '../sections/landing1/FeeSection'
import { Section, Title, Text, SvgMode, Box } from '../components/Core'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import styled from 'styled-components'
import { breakpoints } from '../utils'

import GetStarted from '../sections/landing1/GetStarted'

import ArrowRed from '../assets/image/svg/arrowRed.inline.svg'
import ArrowGreen from '../assets/image/svg/arrowGreen.inline.svg'
import ArrowWhite from '../assets/image/svg/arrowWhite.inline.svg'
import ArrowBlack from '../assets/image/svg/arrowBlack.inline.svg'

import IconCharts from '../assets/image/svg/ic_charts.inline.svg'
import IconExchanges from '../assets/image/svg/ic_exchanges.inline.svg'
import IconHistory from '../assets/image/svg/ic_history.inline.svg'
import IconAlert from '../assets/image/svg/alertNew.inline.svg'
import IconChartsDark from '../assets/image/svg/ic_chartsDark.inline.svg'
import IconExchangesDark from '../assets/image/svg/ic_exchangesDark.inline.svg'
import IconHistoryDark from '../assets/image/svg/ic_historyDark.inline.svg'
import IconAlertDark from '../assets/image/svg/alertNewDark.inline.svg'

import TradeIniciatedLight from '../assets/image/svg/trade-initiated-white.inline.svg'
import TradeExecutedLight from '../assets/image/svg/trade-executed-white.inline.svg'
import MarkerTakerLight from '../assets/image/svg/trade-marker-taker-white.inline.svg'

import TradeIniciatedDark from '../assets/image/svg/trade-initiated-black.inline.svg'
import TradeExecutedDark from '../assets/image/svg/trade-executed-black.inline.svg'
import MarkerTakerDark from '../assets/image/svg/trade-marker-taker-black.inline.svg'

import TradeSettledLight from '../assets/image/svg/trade-settled-white.inline.svg'
import TradeSettledDark from '../assets/image/svg/trade-settled-black.inline.svg'
import pricingHero from '../assets/image/svg/pricingHero.svg'


import { theme } from '../utils'

const ArrowRedStyled = styled(ArrowRed)`
  width: 150px;
  @media (max-width: ${breakpoints.xl}px) {
    width: 120px;
    margin-left: 3px;
  }
`

const TradingPotencialStyled = styled.div`
  .display-web {
    display: block !important;
    p {
      max-width: 200px;
      line-height: 21px;
      &.fees {
        max-width: 130px;
      }
    }
  }

  .display-mob {
    display: none !important;
  }

  @media (max-width: 1100px) {
    .display-web {
      display: none !important;
    }

    .display-mob {
      display: flex !important;
    }

    .arrow-container {
      display: flex;
      flex-direction: row;

      &_inner {
        flex: 1;
        flex-direction: column;
      }

      .row-item {
        flex: 1;
        flex-direction: column;
        margin-bottom: 20px;

        &:not(:first-child) {
          margin-bottom: 25px;
        }

        .arrow-container {
          flex-direction: column;
        }

        &_col {
          width: 100%;
          margin-top: 10px;
        }
      }

      .text-container {
        height: auto !important;
      }
    }

    .row-item-inner {
      height: auto !important;
    }

    .arrow-container-inner {
      height: auto;
      position: relative;
      margin-bottom: 22px;

      &.custom {
        height: auto !important;
      }

      p {
        max-width: 200px;
      }

      svg {
        top: 100px;
        left: 0;
        position: relative;
        transform: translate(-37%, 30%) rotate(90deg);
        margin: 10px 0;
        position: absolute;
      }
    }

    .arrow-container-inner.short {
      height: 85px;
      svg {
        top: 0;
        width: 100%;
      }
    }

    .arrow-container-inner.large {
      height: 277px;
    }
  }

  @media (max-width: 800px) {
    .custom-title {
      font-size: 18px;
    }
  }
`

const BoxStyled = styled(Box)`
  height: 200px;
  @media (max-width: ${breakpoints.lg}px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    h2 {
      max-width: 200px;
    }  
  }
`;

const TitleStyled = styled(Title)`
  font-size: 21px;
  font-weight: 100;
  line-height: 26px;
  margin-top: 21px;
`;


const FeatureCard = ({ icon, title, className, children, ...rest }) => {
  const { ready } = useTranslation(['pricing'], { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  return ready && (
    <Col lg="3" md="6" sm="12">
      <BoxStyled
        border="1px solid"
        borderColor="border"
        bg={userDarkMode ? 'black' : '#F0F0F0'}
        borderRadius={10}
        py="25px"
        px="30px"
        mb={4}
        {...rest}
      >
        {children}
        <TitleStyled color={userDarkMode ? 'light' : 'dark'}>
          {title}
        </TitleStyled>
      </BoxStyled>
    </Col>
  )
}

const Pricing = () => {
  const { t, ready } = useTranslation(['pricing', 'tables', 'landing'], { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  const sectionBackgroundDark = userDarkMode
    ? theme.colors.black
    : theme.colors.lightGray

  const textStyle = {
    color: userDarkMode ? theme.colors.bg : theme.colors.dark,
    color: userDarkMode ? theme.colors.bg : theme.colors.dark,
    color: userDarkMode ? theme.colors.bg : theme.colors.dark,
    fontSize: '16px',
  }

  const contentConfig = {
    leftCol: '6',
    rightCol: '5',
    style: {
      paddingTop: 40,
      justifyContent: 'justify-content-start',
    },
  }

  const tradingItemsStyle = {
    fontSize: '21px',
    marginTop: '20px',
    lineHeight: '24px',
    color: userDarkMode ? 'lightGray' : 'dark',
  }

  const customFeeSection = {
    leftCol: '3',
    rightCol: '8',
    emailInput: false,
    background: theme.colors.bgDarkGray,
    title: ready && t('tightSpreads'),
    firstP: ready && t('doYou'),
  }

  return ready && (
    <>
      <MetaData page="pricing" />
      <PageWrapper headerDark footerDark>
        <Hero
          bg={sectionBackgroundDark}
          title={t('title')}
          className="mt-5"
          img={true}
          imgSrc={pricingHero}
          firstP={t('techPlatforms')}
        >
          {t('subTitle')}
        </Hero>
        <FeeSection
          config={customFeeSection}
          customEmail={true}
          isEmailForm={false}
        />

        <Section bg={sectionBackgroundDark} className="pb-5">
          <Container>
            <Row className="d-flex pb-4">
              {/* Left Side Content */}
              <Col lg="3" className="pr-lg-0 pl-lg-0">
                <Title color={textStyle.color}>
                  {t('tradingPotentialTitle')}
                </Title>
                <p style={{ color: textStyle.color, fontSize: '20px' }}>
                  {t('tradingPotentialDescription')}
                </p>
              </Col>

              {/* Right Side Content */}
              <Col lg="9" className="pr-lg-0 pl-lg-5">
                <TradingPotencialStyled>
                  <Col lg="12" className="arrow-container pr-lg-0 pl-lg-0 pr-0">
                    {/* First Row */}
                    <Row
                      lg="12"
                      className="arrow-container_inner d-flex align-items-start pb-4"
                    >
                      {/* Row Title */}
                      <Row className="d-flex pb-4">
                        <Text className="custom-title" color={textStyle.color}>
                          {t('lsSpreadTitle')}
                        </Text>
                      </Row>

                      {/* Row Items */}
                      {/* <Row lg="10" className="row-item d-flex align-items-center pb-4 mr-0 ml-0" style={{justifyContent: 'space-between' }}> */}
                      <Row
                        lg="12"
                        className="row-item w-100 d-flex align-items-center pb-4 mr-0 ml-0"
                      >
                        {/* Trade is initiated */}
                        <Col
                          lg="6"
                          className="row-item_col ml-0 mr-0 pl-0 pr-0"
                        >
                          <Row className="ml-0 mr-0 d-flex arrow-container">
                            <Row
                              md="1"
                              lg="12"
                              className="row-item-inner ml-0 mr-0 pl-0 pr-0 d-flex align-items-center"
                              style={{ height: '70px' }}
                            >
                              <Col lg="2" xl="3" className="mr-0 ml-0 pl-0">
                                <SvgMode
                                  Dark={TradeIniciatedLight}
                                  Light={TradeIniciatedDark}
                                  isDarkMode={userDarkMode}
                                />
                              </Col>

                              <Col
                                lg="7"
                                xl="3"
                                className="display-mob arrow-container-inner ml-0 mr-0 pl-0 pr-0 mt-2 d-flex flex-column"
                                style={{ height: '80px' }}
                              >
                                <Text style={textStyle}>
                                  {t('tradeInitiatedTitle')}
                                </Text>
                                <Text
                                  variant="small"
                                  style={{
                                    color: textStyle.color,
                                    lineHeight: '18px',
                                  }}
                                >
                                  <small>
                                    {t('tradeInitiatedDescription')}
                                  </small>
                                </Text>
                              </Col>

                              <Col
                                lg="9"
                                xl="3"
                                className="arrow-container-inner large ml-0 mr-0 pl-0 pr-0"
                              >
                                <ArrowGreen />
                              </Col>
                            </Row>

                            <Row
                              xl="12"
                              className="display-web arrow-container-inner ml-0 mr-0 pl-0 pr-0 mt-2 d-flex flex-column"
                              style={{ height: '80px' }}
                            >
                              <Text style={textStyle}>
                                {t('tradeInitiatedTitle')}
                              </Text>
                              <Text
                                variant="small"
                                style={{
                                  color: textStyle.color,
                                  lineHeight: '18px',
                                }}
                              >
                                <small>
                                  {t('tradeInitiatedDescription')}
                                </small>
                              </Text>
                            </Row>
                          </Row>
                        </Col>

                        {/* Trade is executed */}
                        <Col
                          lg="3"
                          className="row-item_col ml-0 mr-0 pl-0 pr-0"
                        >
                          <Row className="ml-0 mr-0 d-flex arrow-container">
                            <Row
                              lg="12"
                              className="row-item-inner ml-0 mr-0 pl-0 pr-0 d-flex align-items-center"
                              style={{ height: '70px' }}
                            >
                              <Col lg="3" className="mr-0 ml-0 pl-0 pr-0">
                                <SvgMode
                                  Dark={TradeExecutedLight}
                                  Light={TradeExecutedDark}
                                  isDarkMode={userDarkMode}
                                />
                              </Col>

                              <Col
                                lg="12"
                                className="display-mob row-item-inner pl-0 pr-0 ml-0 mr-0 mt-2 d-flex flex-column"
                                style={{ height: '80px' }}
                              >
                                <Text style={textStyle}>
                                  {t('tradeExecutedTitle')}
                                </Text>
                                <Text
                                  variant="small"
                                  style={{ color: textStyle.color }}
                                >
                                  <small>
                                    {t('tradeExecutedDescription')}
                                  </small>
                                </Text>
                              </Col>

                              <Col
                                lg="7"
                                className="arrow-container-inner short d-flex align-items-center mr-0 ml-0 pl-0 pr-0"
                              >
                                <SvgMode
                                  width="240"
                                  Dark={ArrowWhite}
                                  Light={ArrowBlack}
                                  isDarkMode={userDarkMode}
                                  className="short-arrow"
                                />
                              </Col>
                            </Row>

                            <Row
                              xl="12"
                              className="display-web arrow-container-inner ml-0 mr-0 pl-0 pr-0 mt-2 d-flex flex-column"
                              style={{ height: '80px' }}
                            >
                              <Text style={textStyle}>
                                {t('tradeExecutedTitle')}
                              </Text>
                              <Text
                                variant="small"
                                style={{ color: textStyle.color }}
                              >
                                <small>
                                  {t('tradeExecutedDescription')}
                                </small>
                              </Text>
                            </Row>
                          </Row>
                        </Col>

                        {/* Trade is settled */}
                        <Col
                          lg="2"
                          className="row-item_col ml-0 mr-0 pl-0 pr-0"
                        >
                          <Row className="ml-0 mr-0 d-flex arrow-container">
                            <Row
                              className="row-item-inner ml-0 mr-0 d-flex align-items-end"
                              style={{ height: '70px' }}
                            >
                              <Col lg="5" className="mr-1 ml-0 pl-0">
                                <SvgMode
                                  Dark={TradeSettledLight}
                                  Light={TradeSettledDark}
                                  isDarkMode={userDarkMode}
                                />
                              </Col>
                            </Row>

                            <Col
                              lg="12"
                              className="display-web ml-0 mr-0 pr-0 pl-0 mt-3 d-flex flex-column"
                              style={{ height: '80px' }}
                            >
                              <Text style={textStyle}>
                                {t('tradeSettledTitle')}
                              </Text>
                              <Text
                                variant="small"
                                style={{
                                  color: textStyle.color,
                                  lineHeight: '18px',
                                }}
                              >
                                <small>
                                  {t('tradeSettledDescription')}
                                </small>
                              </Text>
              ``              </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Row>

                    {/* Second Row */}
                    <Row
                      lg="12"
                      className="arrow-container_inner d-flex align-items-start pb-4"
                    >
                      {/* Row Title */}
                      <Row className="d-flex align-items-center pb-4">
                        <Text className="custom-title" color={textStyle.color}>
                          {t('otherExchangesTitle')}
                        </Text>
                      </Row>

                      {/* Row Items */}
                      <Row
                        lg="11"
                        className="row-item d-flex align-items-center pb-4 mr-0 ml-0"
                      >
                        {/* Trade is initiated */}
                        <Col
                          lg="3"
                          className="row-item_col ml-0 mr-0 pl-0 pr-0"
                        >
                          <Row className="ml-0 mr-0 d-flex arrow-container">
                            <Row
                              lg="12"
                              className="row-item-inner ml-0 mr-0 pl-0 pr-0 d-flex align-items-center w-100"
                              style={{ height: '70px' }}
                            >
                              <Col lg="2" xl="3" className="mr-0 ml-0 pl-0">
                                <SvgMode
                                  Dark={TradeIniciatedLight}
                                  Light={TradeIniciatedDark}
                                  isDarkMode={userDarkMode}
                                />
                              </Col>

                              <Col
                                lg="7"
                                xl="3"
                                className="display-mob arrow-container-inner custom ml-0 mr-0 pl-0 pr-0 mt-2 d-flex flex-column"
                                style={{ height: '80px' }}
                              >
                                <Text style={textStyle}>
                                  {t('tradeInitiatedTitle')}
                                </Text>
                              </Col>

                              <Col
                                lg="9"
                                xl="3"
                                className="arrow-container-inner short ml-0 mr-0 pl-0 pr-0"
                              >
                                <ArrowRedStyled />
                              </Col>
                            </Row>

                            <Row
                              lg="12"
                              className="display-web arrow-container-inner ml-0 mr-0 pl-0 pr-0 mt-2 d-flex flex-column"
                              style={{ height: '80px' }}
                            >
                              <Text style={textStyle}>
                                {t('tradeInitiatedTitle')}
                              </Text>
                            </Row>
                          </Row>
                        </Col>

                        {/* Maker/taker fees added to trade */}
                        <Col
                          lg="3"
                          className="row-item_col ml-0 mr-0 pl-0 pr-0"
                        >
                          <Row className="ml-0 mr-0 d-flex arrow-container">
                            <Row
                              lg="12"
                              className="row-item-inner ml-0 mr-0 pl-0 pr-0 d-flex align-items-center"
                            >
                              <Col lg="3" className="mr-0 ml-0 pl-0 pr-0">
                                <SvgMode
                                  Dark={MarkerTakerLight}
                                  Light={MarkerTakerDark}
                                  isDarkMode={userDarkMode}
                                />
                              </Col>

                              <Col
                                lg="7"
                                xl="3"
                                className="display-mob arrow-container-inner custom ml-0 mr-0 pl-0 pr-0 mt-2 d-flex flex-column"
                                style={{ height: '80px' }}
                              >
                                <Text
                                  style={{
                                    color: textStyle.color,
                                    fontSize: textStyle.fontSize,
                                    lineHeight: '22px',
                                    maxWidth: '150px',
                                  }}
                                >
                                  {t('tradeMakerTitle')}
                                </Text>
                              </Col>

                              <Col
                                lg="7"
                                className="arrow-container-inner short  ml-0 mr-0 pl-0 pr-0"
                              >
                                <ArrowRedStyled />
                              </Col>
                            </Row>
                            <Row
                              lg="12"
                              className="display-web arrow-container-inner ml-0 mr-0 pl-0 pr-0 mt-2 d-flex flex-column w-100"
                              style={{ height: '80px' }}
                            >
                              <Text style={textStyle} className="fees">
                                {t('tradeMakerTitle')}
                              </Text>
                            </Row>
                          </Row>
                        </Col>

                        {/* Trade is executed */}
                        <Col
                          lg="3"
                          className="row-item_col ml-0 mr-0 pl-0 pr-0"
                        >
                          <Row className="ml-0 mr-0 d-flex arrow-container">
                            <Row
                              lg="12"
                              className="row-item-inner ml-0 mr-0 pl-0 pr-0 d-flex align-items-center"
                              style={{ height: '70px' }}
                            >
                              <Col lg="3" className="mr-0 ml-0 pl-0 pr-0">
                                <SvgMode
                                  Dark={TradeExecutedLight}
                                  Light={TradeExecutedDark}
                                  isDarkMode={userDarkMode}
                                />
                              </Col>

                              <Col
                                lg="12"
                                className="display-mob row-item-inner pl-0 pr-0 ml-0 mr-0 mt-2 d-flex flex-column"
                                style={{ height: '80px' }}
                              >
                                <Text style={textStyle}>
                                  {t('tradeExecutedTitle')}
                                </Text>
                              </Col>

                              <Col
                                lg="7"
                                className="arrow-container-inner short d-flex align-items-center mr-0 ml-0 pl-0 pr-0"
                              >
                                <SvgMode
                                  width="240"
                                  Dark={ArrowWhite}
                                  Light={ArrowBlack}
                                  isDarkMode={userDarkMode}
                                  className="short-arrow"
                                />
                              </Col>
                            </Row>

                            <Row
                              xl="12"
                              className="display-web arrow-container-inner ml-0 mr-0 pl-0 pr-0 mt-2 d-flex flex-column"
                              style={{ height: '80px' }}
                            >
                              <Text style={textStyle}>
                                {t('tradeExecutedTitle')}
                              </Text>
                            </Row>
                          </Row>
                        </Col>

                        {/* Trade is settled */}
                        <Col
                          lg="2"
                          className="row-item_col ml-0 mr-0 pl-0 pr-0"
                        >
                          <Row className="ml-0 mr-0 d-flex arrow-container">
                            <Row
                              className="row-item-inner ml-0 mr-0 d-flex align-items-end"
                              style={{ height: '70px' }}
                            >
                              <Col lg="5" className="mr-1 ml-0 pl-0">
                                <SvgMode
                                  Dark={TradeSettledLight}
                                  Light={TradeSettledDark}
                                  isDarkMode={userDarkMode}
                                />
                              </Col>
                            </Row>

                            <Col
                              lg="12"
                              className="display-web ml-0 mr-0 pr-0 pl-0 mt-3 d-flex flex-column"
                              style={{ height: '80px' }}
                            >
                              <Text style={textStyle}>
                                {t('tradeSettledTitle')}
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Row>
                  </Col>
                </TradingPotencialStyled>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section
          bg={userDarkMode ? theme.colors.bgDarkGray : theme.colors.light}
        >
          <Container>
            <Row>
              <Col>
                <Title
                  className="mb-5"
                  variant={"SectionTitle"}
                  style={{ color: userDarkMode ? '#FAFAFA' : 'black' }}
                >
                  {t('tradingExperience')}
                </Title>
              </Col>
            </Row>
            <Row>
              <FeatureCard title={t('chartingTools')}>
                <SvgMode
                  Dark={IconChartsDark}
                  Light={IconCharts}
                  isDarkMode={userDarkMode}
                />
              </FeatureCard>
              <FeatureCard title={t('marketDepth')}>
                <SvgMode
                  Dark={IconExchangesDark}
                  Light={IconExchanges}
                  isDarkMode={userDarkMode}
                />
              </FeatureCard>
              <FeatureCard title={t('historicalPrice')}>
                <SvgMode
                  Dark={IconHistoryDark}
                  Light={IconHistory}
                  isDarkMode={userDarkMode}
                />
              </FeatureCard>
              <FeatureCard title={t('realTimePrice')}>
                <SvgMode
                  Dark={IconAlertDark}
                  Light={IconAlert}
                  isDarkMode={userDarkMode}
                />
              </FeatureCard>
            </Row>
          </Container>
        </Section>
        <GetStarted customEmail={true} />
      </PageWrapper>
    </>
  )
}
export default Pricing
