import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import { Section, Text, Button, Title } from '../../components/Core'
import ExchFeesTable from '../../components/Table/ExchFeesTable'
import EmailForm from '../../components/EmailForm/EmailForm'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Link from '../../components/Link'
import { theme } from '../../utils'
import { breakpoints } from '../../utils'

const ButtonStyle = styled(Button)`
@media (max-width: ${breakpoints.md}px) {
  width: 100%;
}
background-color: #124D95;
`

/**
 * @param {Object} t Get access to the translation function
 * @param {Object} customEmail Set custom style to EmailForm Component
 * @param {Object} config If exist display the EmailForm if not display the starting trading button
 */
const RenderBottomContent = ({ isEmailForm, customEmail, t }) => {
  return isEmailForm ? (
    <Col lg="12" xl="12" className="pb-5 pt-5" style={{ paddingLeft: 0 }}>
      <EmailForm
        formType="GetStarted"
        btnText={t('start')}
        {...{ customEmail }}
      />
    </Col>
  ) : (
    <a href={process.env.LS_SIGNUP}>
      <ButtonStyle>{t('startTrading')}</ButtonStyle>
    </a>
  )
}

/**
 * @param {Object} t Get access to the translation function
 * @param {Object} config If exist display the EmailForm Title if not display the starting trading Title
 */
const RenderBottomTitle = ({ config, t }) => {
  return config ? (
    <span>
      {' '}
      <strong> {t('whyPay')}</strong> {t('openfreeAccount')}{' '}
    </span>
  ) : (
    t('whyPay')
  )
}

/**
 * @param {Object} t Get access to the translation function
 * @param {Object} config If exist display Link Component
 */
const RenderLink = ({ config, lang, t }) => {
  return (
    !config && (
      <Link to={`/${lang}/pricing`}>
        <span style={{ color: '#3B96FF', textDecoration: 'underline' }}>
          {t('whyUsPage.morePricing')}
        </span>
      </Link>
    )
  )
}

const FeeSection = props => {
  const { t } = useTranslation('landing', { useSuspense: false });
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  const customDarkMode =
    userDarkMode && props.config ? props.config.background : theme.colors.black

  return (
    <>
      {/* <!-- FeeSection section --> */}
      <Section
        bg={userDarkMode ? 'bgDarkGray' : 'light'}
        className="position-relative"
      >
        <Container>
          <Row>
            <Col lg="4" sm="12">
              <Title variant={"highlight"} style={{ color: props.config.titleColor ? props.config.titleColor : userDarkMode ? '#FAFAFA' : 'black'}}>{props.config.title}</Title> 
              <Text
                color={userDarkMode ? '#FAFAFA' : 'darkShade'}
                mb={4}
              >
                {props.config.firstP}
              </Text>
              <RenderLink config={props.config} lang={props.lang} {...{ t }} />
            </Col>
            <Col lg="8" sm="12">
              <ExchFeesTable />
              <Text
                style={{ fontWeight: 'bold' }}
                color={userDarkMode ? '#FAFAFA' : 'darkShade'}
                mb={4}
              >
                <RenderBottomTitle config={props.config} {...{ t }} />
              </Text>

              <RenderBottomContent
                isEmailForm={props.isEmailForm}
                customEmail={props.customEmail}
                {...{ t }}
              />
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default FeeSection
