import * as styles from './EmailForm.module.css'
import React, { useState, useEffect } from 'react'
import { device } from '../../utils'
import { InputGroup, Button, Text } from '../../components/Core'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import 'isomorphic-fetch'
import { useSelector } from 'react-redux'
import theme from '../../utils/theme'


const EmailForm = ({ customEmail, btnText, ...props }) => {
  // const csrfCoinigy = useSelector(state => state.user.csrf_token)
  const { t } = useTranslation()
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  // Email Input Style : Light.
  const inputLight = {
    color: theme.colors.darkGray,
    background: theme.colors.light,
    placeholder: theme.colors.mediumGray,
  }

  // Email Input Style : Dark.
  const inputDark = {
    color: theme.colors.light,
    background: theme.colors.darkGray,
    placeholder: theme.colors.lightGray,
  }

  const inputColorsTheme = userDarkMode ? inputDark : inputLight

  // Custom Config.
  const inputColor = customEmail ? inputColorsTheme.color : theme.colors.light
  const inputBackground = customEmail
    ? inputColorsTheme.background
    : theme.colors.primary
  const inputPlaceHolder = customEmail
    ? inputColorsTheme.placeholder
    : theme.colors.lighter
  const inputBorder = customEmail && theme.colors.lighter
  const btnContent = btnText ? btnText : t('landing.getStarted')

  const [emailValue, setEmailValue] = useState(false)
  const hubspot_identify = () => {
    var _hsq = (window._hsq = window._hsq || [])
    _hsq.push(['identify', { email: emailValue }])
    _hsq.push(['trackPageView'])
  }
  useEffect(() => {
    localStorage.setItem('cgyEmail', emailValue)
  }, [emailValue])

  const handleSubmit = () => {
    hubspot_identify()
    window.open(`${process.env.LS_SIGNUP}`, '_blank')
  }

  // const handleSubmit =  event => {
  //   const form = event.currentTarget
  //   if (form.checkValidity() === false) {
  //     event.preventDefault()
  //     event.stopPropagation()
  //   }
  //    setValidated(true)
  // }

  // const fetchCsrf = () => {
  //   if (!csrfCoinigy) {
  //     fetch(`https://staging.coinigy.com/getjson/fetch_hash`, {
  //       method: "GET",
  //       credentials: "include"
  //     })
  //       .then(response => response.json())
  //       .then(resultData => {
  //         setCsrfCoinigy(resultData.hash);
  //       })
  //       .catch(function(error) {
  //       });
  //   }
  // }

  // props.formType determines which style of form is rendered. "GetStarted" (default), "Hero"
  if (props.formType === 'Hero') {
    return (
      <div>
        <div className="position-relative">
          <InputGroup
            type="email"
            name="f312ee3b63be1a5aa1db80fe0d7dcad8"
            icon={
              <i
                style={{ color: inputPlaceHolder }}
                className="icon icon-email-84 mt-1"
              />
            }
            placeholder={t('landing.yourEmail')}
            onChange={e => setEmailValue(e.target.value)}
            required
            border="none"
            borderRadius={6}
            css={`
              background-color: ${inputBackground};
              box-shadow: 0 42px 54px rgba(0, 0, 0, 0.16);
              color: ${inputColor};
              padding-top: 0.9rem;
              padding-bottom: 1rem;
              ::placeholder {
                color: ${inputPlaceHolder};
              }
              @media${device.sm} {
                padding-right: 160px;
              }
            `}
          />
          {/* <input type="hidden" name="csrf_coinigy" value={csrfCoinigy} /> */}
          <Button
            className={styles.signupButton}
            type="submit"
            onClick={() => handleSubmit()}
            style={{
              fontSize: '16px',
              paddingTop: '0.65rem',
              paddingBottom: '0.7rem',
            }}
            background={'#124D95'}
            css={`
              margin-top: 1rem;
              @media${device.sm} {
                margin-top: 0;
                position: absolute;
                top: 50%;
                right: 6px;
                transform: translateY(-50%);
                padding-top: 0.85rem;
                padding-bottom: 0.85rem;
              }
            `}
          >
            {btnContent}
          </Button>
        </div>
      </div>
    )
  } else if (props.formType === 'mailLite') {
    return (
        <div
          className="ml-form-embed"
          data-account="1887272:q6x0d1k8b7"
          data-form="4573645:s9a7v2"
          css={`
            #mlb2-4573645.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow button {
              background-color: #3377C6 !important;
              width: 132px;
              height: 44px;
              border-radius: 6px;
              border-color: #3377C6;
              margin-top: 5px !important;
              margin-left: 150px !important;
              font-style: normal;
              font-weight: 400;
              font-size: 16px !important;
              line-height: 20px;
            }
            #mlb2-4573645.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow button:hover {
              background-color: #333!important;
              border-color: #333!important;
            }
            #mlb2-4573645.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody {
              padding: 0px;
            }
            #mlb2-4573645.ml-form-embedContainer .ml-form-embedWrapper {
              background-color: transparent;
              margin-left: 0px;
              align-items: left;
            }
            #mlb2-4573645.ml-form-embedContainer .ml-form-align-center {
              text-align: start;
            }
            #mlb2-4573645.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow input {
              width: 570px !important;
              height: 54px;
              background-color: ${
                userDarkModeSelector ? '#191B20 !important' : '#F0F0F0 !important'
              }
              border-radius: 6px !important;
              border-color: transparent !important;
              font-style: normal;
              font-weight: 400;
              font-size: 18px !important;
              line-height: 20px;
              overflow: hidden;
              padding: 15px 15px 8px 15px;
              box-shadow: 0 42px 54px rgba(65, 65, 101, 0.16);
            }
            .ml-form-formContent.horozintalForm .ml-form-horizontalRow .ml-input-horizontal {
              width: 70%;
            }
            #mlb2-4573645.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody .ml-form-successContent h4 {
              color: ${
                userDarkModeSelector ? '#FAFAFA !important' : 'black !important'
              }
            }
            #mlb2-4573645.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody .ml-form-successContent p {
              color: ${
                userDarkModeSelector ? '#FAFAFA !important' : 'black !important'
              }
            }
            @media${device.xs} {
              #mlb2-4573645 .ml-mobileButton-horizontal button {
                background-color: #3377C6 !important;
                width: 100% !important;
                height: 44px;
                border-radius: 6px;
                border-color: #3377C6;
                margin-top: 5px !important;
                font-style: normal;
                font-weight: 400;
                font-size: 16px !important;
                line-height: 20px;
              }
            }
          `}
        ></div>
    )
  } else {
    return (
      <div>
        <InputGroup
          type="email"
          name="f312ee3b63be1a5aa1db80fe0d7dcad8"
          icon={
            <i
              style={{ color: inputPlaceHolder }}
              className="icon icon-email-84"
            />
          }
          placeholder={t('landing.yourEmail')}
          onChange={e => setEmailValue(e.target.value)}
          required
          border="none"
          css={`
            background-color: ${inputBackground};
            box-shadow: 0 42px 54px rgba(0, 0, 0, 0.16);
            color: ${inputColor};
            ::placeholder {
              color: ${inputPlaceHolder};
            }
            border: 1px solid ${inputBorder};
            @media${device.sm} {
              padding-right: 210px;
            }
          `}
        />
        {/* <input type="hidden" name="csrf_coinigy" value={csrfCoinigy} /> */}
        <Button
          className={styles.signupButton}
          type="submit"
          onClick={() => handleSubmit()}
          style={{ fontSize: '16px' }}
          background={'#124D95'}
          css={`
            margin-top: 1rem;
            @media${device.sm} {
              margin-top: 0;
              position: absolute;
              top: 50%;
              right: 1.5rem;
              transform: translateY(-50%);
            }
          `}
        >
          {btnContent}
        </Button>
      </div>
    )
  }
}

export default EmailForm
